import './../styles/ElementSidebar.css'
import './../styles/ResourceLink.css'
import { useConcept, useTopic } from './../state/project'
import { useElementState } from './../state/element'
import Article from './icons/Article'
import Slack from './icons/Slack'
import Progress from './Progress'
import HintModal from './HintModal'

function ElementSidebar() {
  const topic = useTopic()
  const concept = useConcept()
  const { element } = useElementState()

  const progress = element.isSummary
    ? concept.progress
    : element.progress
  const progressHelpText = progress < 100
    ? `You have reached ${progress}% of the required topics. Please continue researching.`
    : `Congratulations, you have completed this exercise! You can continue to the next lesson.`

  return (
    <aside className="CodedoorEditor__ElementSidebar">
      <section>
        <h1 className="label">Your Progress</h1>
        <p className="sub">{progressHelpText}</p>

        <div className="statistic">
          <span className="title">{progress}<span className="label"> %</span></span>
          <Progress percentage={progress} />
        </div>
      </section>
      <section>
        <h1 className="label">Resources</h1>
        <p className="sub">The following links are a good starting point for your research.</p>
        <ul className="resources">
          {
            topic.resources.map(r => (
              <li key={r.id} className="CodedoorEditor__ResourceLink">
                <a href={r.url} target="_blank" rel="noreferrer" title={r.name}>
                  <span className="icon">
                    <Article />
                  </span>
                  <span className="text">{r.name}</span>
                </a>
              </li>
            ))
          }
        </ul>
      </section>
      <section>
        <h1 className="label">Stuck?</h1>
        <div className="CodedoorEditor__ResourceLink always-active help">
          <a href="https://slack.com/app_redirect?channel=CD44Y4GUW" target="_blank" rel="noreferrer">
            <span className="icon">
              <Slack />
            </span>
            <span className="text">Get help on Slack</span>
          </a>
        </div>
        <HintModal element={element} className="help" />
      </section>
    </aside>
  )
}

export default ElementSidebar
