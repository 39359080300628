import { Redirect } from 'react-router-dom'
import { storage } from './../helpers/LocalStorage'

function HydrateSession() {
  const projectId = storage.getItem('current_project')

  if (!projectId) {
    return window.location = 'https://codedoor.com/dashboardv3/projects'
  }

  return <>
    <Redirect to={`/projects/${projectId}`} />
  </>
}

export default HydrateSession
