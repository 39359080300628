import classNames from 'classnames'
import './../styles/Button.css'

function Button({ onClick, type = 'primary', children, className, ...props }) {
  return <button
    onClick={onClick}
    className={classNames('CodedoorEditor__Button', className, `CodedoorEditor__Button__${type}`)} {...props}
  >
    {children}
  </button>
}

export default Button
