import { useAppState } from '../state/app'
import { useProject } from '../state/project'
import { CloseButton } from './icons/Close'
import './../styles/ProjectNavigation.css'
import NavigationTopic from './NavigationTopic'
import Topic from '../state/models/Topic'

function ProjectNavigation() {
  const { toggleNavigation } = useAppState()
  const project = useProject()

  return (
    <aside className="CodedoorEditor__ProjectNavigation">
      <header>
        <a href={`https://codedoor.com/dashboardv3/projects/${project.id}`}>
          <img src={process.env.PUBLIC_URL + '/cddr-logo.png'} alt="Codedoor Logo"/>
        </a>
        <h1>{project.title}</h1>
        <CloseButton onClick={() => toggleNavigation()} className="close" aria-label="Close Project Navigation" />
      </header>

      <main>
        <h1 className="label">Topics</h1>
        <ul>
          { project.topics
              .map(topic => new Topic(topic))
              .map(topic => <NavigationTopic topic={topic} key={topic.id} />)
          }
        </ul>
      </main>
  </aside>
  )
}

export default ProjectNavigation
