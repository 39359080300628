import Button from './Button'
import Icon from './Icon'

function Article() {
  return <Icon>
    <path d="M14 4V5.33333H2V4H14ZM2 12H8V10.6667H2V12ZM2 8.66667H14V7.33333H2V8.66667Z" fill="currentColor"/>
  </Icon>
}

function ArticleButton(props) {
  return <Button {...props}><Article /></Button>
}

export default Article
export { ArticleButton }
