import './../styles/Progress.css'

function Progress({ percentage }) {
  const radius = 40
  const circumference = Math.PI * radius * 2
  const innerStrokeWidth = 2
  const strokeWidth = percentage > 0 ? 4 : 0
  const stroke = percentage >= 50 ? `var(--green)` : `var(--primary)`

  return (
    <svg viewBox='0 0 100 100' className="CodedoorEditor__Progress">
      <circle cx="50" cy="50" r={radius} style={{ strokeWidth: innerStrokeWidth }}></circle>
      <circle
        cx="50" cy="50"
        r={radius}
        style={{ strokeWidth, stroke }}
        strokeDasharray={`${circumference * (percentage/100)}, ${circumference}`}
      ></circle>
	</svg>
  )
}

export default Progress
