import Button from './Button'
import Icon from './Icon'

function Circle() {
  return <Icon>
    <path fillRule="evenodd" clipRule="evenodd" d="M14.6667 8C14.6667 11.6819 11.6819 14.6667 8 14.6667C4.3181 14.6667 1.33333 11.6819 1.33333 8C1.33333 4.3181 4.3181 1.33333 8 1.33333C11.6819 1.33333 14.6667 4.3181 14.6667 8ZM12.6667 8C12.6667 10.5773 10.5773 12.6667 8 12.6667C5.42267 12.6667 3.33333 10.5773 3.33333 8C3.33333 5.42267 5.42267 3.33333 8 3.33333C10.5773 3.33333 12.6667 5.42267 12.6667 8Z" fill="currentColor"/>
  </Icon>
}

function CircleButton(props) {
  return <Button {...props}><Circle /></Button>
}

export default Circle
export { CircleButton }
