import { useEffect } from 'react'
import { Async } from 'react-async'
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom'
import { loadProject } from '../api/project'
import { ProvideElement } from '../state/element'
import { ProjectContext } from '../state/project'
import { LOCALSTORAGE_KEYS, storage } from '../helpers/LocalStorage'
import ChooseStep from './ChooseStep'
import EditorLayout from './EditorLayout'
import ProjectNavigation from './ProjectNavigation'

function Project() {
  const { path } = useRouteMatch()
  const { projectId } = useParams()

  useEffect(() => {
    storage.setItem(LOCALSTORAGE_KEYS.CURRENT_PROJECT, projectId)
  })

  return <Async promiseFn={loadProject} id={projectId}>
    <Async.Pending>Loading...</Async.Pending>
    <Async.Fulfilled>
      {project => (
        <ProjectContext.Provider value={project}>
          <ProjectNavigation />

          <Switch>
            <Route path={`${path}/topics/:topicId/concepts/:conceptId/elements/:elementId`}>
              <ProvideElement>
                <EditorLayout />
              </ProvideElement>
            </Route>

            <Route path={[`${path}/topics/:topicId/concepts/:conceptId`, `${path}/topics/:topicId`, '*']}>
              <ChooseStep />
            </Route>
          </Switch>
        </ProjectContext.Provider>
      )}
    </Async.Fulfilled>
    <Async.Rejected>There was an error loading the project!</Async.Rejected>
  </Async>
}

export default Project
