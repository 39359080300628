import { MoonButton } from './icons/Moon'
import { SunButton } from './icons/Sun'
// import { AudioOffButton } from './icons/AudioOff'
// import { AudioOnButton } from './icons/AudioOn'
import { useAppState } from '../state/app'
import './../styles/EditorSettings.css'

function EditorSettings() {
  const {
    themeDark,
    toggleTheme,
    // soundOn,
    // toggleSound,
  } = useAppState()

  return (
    <aside className="CodedoorEditor__EditorSettings">
      {
        themeDark
          ? <SunButton
              onClick={toggleTheme}
              aria-label="Switch the lights on"
            />
          : <MoonButton
              onClick={toggleTheme}
              aria-label="Switch the lights off"
            />
      }
      {/* {
        soundOn
          ? <AudioOnButton
              onClick={toggleSound}
              title="Audio playback is on"
              aria-label="Turn the audio guide on"
            />
          : <AudioOffButton
              onClick={toggleSound}
              title="Audio playback is off"
              aria-label="Turn the audio guide off"
            />
      } */}
    </aside>
  )
}

export default EditorSettings
