import classNames from 'classnames'
import './../../styles/IconButton.css'

function Button({ onClick, children, className, ...props }) {
  return <button onClick={onClick} className={classNames('CodedoorEditor__IconButton', className)} {...props}>
    {children}
  </button>
}

export default Button
