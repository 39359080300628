import React, { useEffect } from 'react'
import { createContext, useContext, useState } from 'react'
import { LOCALSTORAGE_KEYS, storage } from '../helpers/LocalStorage'

const AppContext = createContext()

/**
 * A hook that provides the app state.
 *
 * @param {object} initialState The initial state to provide
 */
function useProvideAppState(initialState) {
  const [navigation, setNavigation] = useState(initialState.navigation || 'open')
  const [sound, setSound] = useState(initialState.sound || 'active')
  const [theme, setTheme] = useState(initialState.theme || 'light')

  // Methods to update state
  const toggleNavigation = () => setNavigation(navigation === 'open' ? 'closed' : 'open')
  const toggleSound = () => setSound(sound === 'active' ? 'muted' : 'active')
  const toggleTheme = () => setTheme(theme === 'light' ? 'dark' : 'light')

  return {
    theme,
    navigation,
    sound,

    get navigationOpen () {
      return navigation === 'open'
    },
    get themeDark () {
      return theme === 'dark'
    },
    get soundOn () {
      return sound === 'active'
    },

    toggleNavigation,
    toggleSound,
    toggleTheme,
  }
}

/**
 * The AppContext state provider component.
 *
 * @param {object} props Props passed into the provider
 */
function ProvideAppState({ project, children }) {
  const state = useProvideAppState({ project, ...storage.getItem(LOCALSTORAGE_KEYS.APP_STATE) })

  // Sync items to local storage
  useEffect(() => storage.setItem(LOCALSTORAGE_KEYS.APP_STATE, state))

  return (
    <AppContext.Provider value={state}>
      {children}
    </AppContext.Provider>
  )
}

/**
 * A hook that uses the application state context.
 */
function useAppState() {
  return useContext(AppContext)
}

export {
  ProvideAppState,
  useAppState,
}
