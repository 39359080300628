import { withRouter } from 'react-router-dom'
import { useAppState } from '../state/app'
import { useConcept } from '../state/project'
import Breadcrumbs from './Breadcrumbs'
import './../styles/TopBar.css'

import { MenuButton } from './icons/Menu'
import NextButton from './NextButton'
import PreviousButton from './PreviousButton'
import ElementNavigationItem from './ElementNavigationItem'

function TopBar() {
  const { navigationOpen, toggleNavigation } = useAppState()
  const concept = useConcept()

  // Fixme - cache this
  concept.updateProgress()

  return (
    <header className="CodedoorEditor__TopBar">
      <div className="left">
        { ! navigationOpen && (
          <MenuButton
            onClick={() => toggleNavigation()}
            className="open"
            aria-label="Open Project Navigation"
          />
        )}
        <Breadcrumbs />
      </div>
      <div className="center">
        { concept.elements.map(e => <ElementNavigationItem element={e} concept={concept} key={e.id} />) }
      </div>
      <div className="right">
        <PreviousButton />
        <NextButton />
      </div>
    </header>
  )
}

export default withRouter(TopBar)
