import { useHistory } from 'react-router-dom'
import { useStep } from '../state/project'
import Button from './Button'

function NextButton() {
  const history = useHistory()
  const { next } = useStep()

  const goNext = () => next
    ? history.push(next)
    : alert('Everything complete!')

  return <Button onClick={goNext}>Continue</Button>
}

export default NextButton
