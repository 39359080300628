import { createContext, useContext, useState } from 'react';
import { useElement } from './project';

const ElementContext = createContext()

/**
 * A hook that uses the current element state.
 */
function useElementState() {
  return useContext(ElementContext)
}

/**
 * The AppContext state provider component.
 *
 * @param {object} props Props passed into the provider
 */
function ProvideElement({ children }) {
  const element = useElement()
  const [, setElement] = useState(element)
  const state = { element, setElement }

  return (
    <ElementContext.Provider value={state}>
      {children}
    </ElementContext.Provider>
  )
}

export {
  ProvideElement,
  useElementState,
}
