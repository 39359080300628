import Button from './Button'
import Icon from './Icon'

function MilestoneCheck() {
  return <Icon>
    <path d="M8 0L16 8L8 16L0 8L8 0Z" fill="currentColor" className="dark"/>
    <path d="M6.66658 9.72667L10.4283 5.95876C10.5566 5.85039 10.7211 5.79443 10.8889 5.80207C11.0566 5.80972 11.2153 5.8804 11.3333 6C11.4512 6.1196 11.5196 6.27929 11.5249 6.44715C11.5301 6.61502 11.4718 6.77868 11.3616 6.90543L7.13325 11.14C7.00863 11.2622 6.84108 11.3306 6.66658 11.3306C6.49208 11.3306 6.32453 11.2622 6.19991 11.14L4.19991 9.14C4.08973 9.01325 4.03144 8.84959 4.0367 8.68172C4.04197 8.51386 4.1104 8.35417 4.22831 8.23457C4.34622 8.11498 4.50492 8.04429 4.6727 8.03664C4.84047 8.029 5.00495 8.08496 5.13325 8.19334L6.66658 9.72667Z" fill="currentColor" className="light"/>
  </Icon>
}

function MilestoneCheckButton(props) {
  return <Button {...props}><MilestoneCheck /></Button>
}

export default MilestoneCheck
export { MilestoneCheckButton }
