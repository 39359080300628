import { LOCALSTORAGE_KEYS as L_KEYS } from '@codedoor/frontend-utils/helpers/LocalStorage'

export { storage } from '@codedoor/frontend-utils'

export function elementLocalStorageKey(id) {
  return `elements.${id}`
}

export const LOCALSTORAGE_KEYS = {
  ...L_KEYS,
  APP_STATE: 'app_state',
  CURRENT_PROJECT: 'current_project',
  LAST_LOCATION: 'last_location',
}
