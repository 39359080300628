import { ElementsMap } from "../state"
import Element from "./Element"

class Concept {
  constructor ({
    id,
    txt,
    elements,
  }) {
    this.id = id
    this.txt = txt
    this.elements = elements.map(e => {
      if (! ElementsMap[e.id]) {
        ElementsMap[e.id] = new Element(e)
      }

      return ElementsMap[e.id]
    })

    this.updateProgress()
  }

  get simpleElements () {
    return this.elements.filter(e => !e.isSummary)
  }

  get isComplete () {
    return this.progress >= 50
  }

  computeProgress () {
    const elements = this.simpleElements

    return Math.round(elements.reduce((p, e) => p + e.progress, 0) / elements.length)
  }

  updateProgress () {
    this.progress = this.computeProgress()
  }
}

export default Concept
