import Button from './Button'
import Icon from './Icon'

function Milestone() {
  return <Icon>
    <path fillRule="evenodd" clipRule="evenodd" d="M15.3334 7.99999L8.00008 0.666656L0.666748 7.99999L8.00008 15.3333L15.3334 7.99999ZM12.6667 8L8 3.33333L3.33333 8L8 12.6667L12.6667 8Z" fill="currentColor"/>
  </Icon>
}

function MilestoneButton(props) {
  return <Button {...props}><Milestone /></Button>
}

export default Milestone
export { MilestoneButton }
