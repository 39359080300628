import { Link, useRouteMatch } from 'react-router-dom'
import classNames from 'classnames'
import './../styles/NavigationItem.css'
import Circle from './icons/Circle'
import CircleCheck from './icons/CircleCheck'

function NavigationItem({ label, to, isComplete = false, children }) {
  const match = useRouteMatch({
    path: to,
  })

  return <li className={classNames('CodedoorEditor__NavigationItem', match ? 'active' : '')}>
    <Link to={to}>
      {
        isComplete
          ? <CircleCheck />
          : <Circle />
      }
      {label}
    </Link>
    { match && children && children.length
      ? <ul>{children}</ul>
      : null
    }
  </li>
}

export default NavigationItem
