import { ConceptsMap } from "../state"
import Concept from "./Concept"

class Topic {
  constructor ({
    id,
    txt,
    concepts,
    resources,
  }) {
    this.id = id
    this.txt = txt
    this.concepts = concepts.map(c => {
      if (! ConceptsMap[c.id]) {
        ConceptsMap[c.id] = new Concept(c)
      }

      return ConceptsMap[c.id]
    })
    this.resources = resources

    this.updateProgress()
  }

  get isComplete () {
    return this.progress >= 50
  }

  computeProgress () {
    return Math.round(this.concepts.reduce((p, e) => p + e.progress, 0) / this.concepts.length)
  }

  updateProgress () {
    this.progress = this.computeProgress()
  }
}

export default Topic
