import { useProject } from '../state/project'
import NavigationItem from './NavigationItem'

function NavigationTopic({ topic }) {
  const project = useProject()
  const topicUrl = `/projects/${project.id}/topics/${topic.id}`

  return <NavigationItem
    to={topicUrl}
    label={topic.txt}
    isComplete={topic.isComplete}
    key={topic.id}
  >
    {
      topic.concepts
        .filter(concept => concept.txt !== topic.txt)
        .map(concept => <NavigationItem
          to={`${topicUrl}/concepts/${concept.id}`}
          label={concept.txt}
          isComplete={concept.isComplete}
          key={concept.id}
        />)
    }
  </NavigationItem>
}

export default NavigationTopic
