import { useConcept, useTopic } from '../state/project'
import ElementEditor from './ElementEditor'

import './../styles/ConceptSummary.css'
import { useElementState } from '../state/element'

function ConceptSummary() {
  const topic = useTopic()
  const concept = useConcept()
  const { element, setElement } = useElementState()

  // FIXME - cache this
  topic.updateProgress()
  concept.updateProgress()

  return (
    <main className="CodedoorEditor__ConceptSummary">
      <header>
        <h1 className="title">{concept.txt}</h1>
        <p className="lead">Please recap what you have learned in about this {concept.title} concept.</p>
        <hr/>
      </header>

      <div className="CodedoorEditor__ConceptSummary__Elements">
        {
          concept.elements.map(e => (
            <ElementEditor
              key={e.id}
              element={e}
              setElement={() => setElement({ ...element })}
            />
          ))
        }
      </div>
    </main>
  )
}

export default ConceptSummary
