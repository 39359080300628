import './../styles/EditorLayout.css'

import ConceptSummary from './ConceptSummary'
import ElementEditor from './ElementEditor'
import ElementSidebar from './ElementSidebar'
import EditorSettings from './EditorSettings'
import TopBar from './TopBar'
import { useElementState } from '../state/element'
import { useEffect } from 'react'
import { LOCALSTORAGE_KEYS, storage } from '../helpers/LocalStorage'
import { useLocation } from 'react-router-dom'

function useSaveLastLocation () {
  const location = useLocation()

  useEffect(() => {
    storage.setItem(LOCALSTORAGE_KEYS.LAST_LOCATION, location.pathname)
  })
}

function EditorLayout() {
  const { element, setElement } = useElementState()

  useSaveLastLocation()

  return <div className="CodedoorEditor__EditorLayout">
    <TopBar />
    {
      element.isSummary
        ? <ConceptSummary />
        : <ElementEditor element={element} setElement={setElement} />
    }
    <ElementSidebar />
    <EditorSettings />
  </div>
}

export default EditorLayout
