import { useAppState } from "../state/app"
import { useConcept, useElement, useProject, useTopic } from "../state/project"
import './../styles/Breadcrumbs.css'

function Item({ children }) {
  return <span>{children}</span>
}

function Breadcrumbs() {
  const { navigationOpen } = useAppState()
  const project = useProject()
  const topic   = useTopic()
  const concept = useConcept()
  const element = useElement()

  return <h1 className="CodedoorEditor__Breadcrumbs">
    {
      ! navigationOpen && <>
        <Item>{project.title}</Item>
        <Item>{topic.txt}</Item>
      </>
    }
    {
      navigationOpen || topic.txt !== concept.txt
        ? <Item>{concept.txt}</Item>
        : null
    }
    {
      element
        ? <Item>{element.txt}</Item>
        : <Item>Summary</Item>
    }
  </h1>
}

export default Breadcrumbs
