import { useHistory } from 'react-router-dom'
import { useStep } from '../state/project'
import Button from './Button'

function PreviousButton() {
  const history = useHistory()
  const { previous } = useStep()

  if (! previous) return null

  const goPrevious = () => previous
    ? history.push(`${previous}`)
    : alert('This is the first step!')

  return <Button onClick={goPrevious} type="secondary">Previous</Button>
}

export default PreviousButton
