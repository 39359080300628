import classNames from 'classnames'
import { updateAuthHeaders } from '../api'
import { sessionStorage, SESSIONSTORAGE_KEYS } from '../helpers/SessionStorage'
import { useQuery } from '../helpers/url'
import { useAppState } from "../state/app"
import './../styles/AppShell.css'

function AppShell({ children }) {
  const { navigation, theme } = useAppState()

  const query = useQuery()
  const authToken = query.has('auth_token')
    ? query.get('auth_token')
    : sessionStorage.getItem(SESSIONSTORAGE_KEYS.AUTH_TOKEN)

  const apiToken = query.has('api_token')
    ? query.get('api_token')
    : sessionStorage.getItem(SESSIONSTORAGE_KEYS.API_TOKEN)

  if (!authToken || !apiToken) {
    return window.location = 'https://codedoor.com/dashboardv3/projects'
  }

  sessionStorage.setItem(SESSIONSTORAGE_KEYS.AUTH_TOKEN, authToken)
  sessionStorage.setItem(SESSIONSTORAGE_KEYS.API_TOKEN, apiToken)

  updateAuthHeaders()

  return <div className={classNames('CodedoorEditor', `navigation-${navigation}`, `theme-${theme}`)}>
    {children}
  </div>
}

export default AppShell
