import Button from './Button'
import Icon from './Icon'

function Close() {
  return <Icon>
    <path d="M11.8533 10.9066C11.9788 11.0322 12.0494 11.2024 12.0494 11.38C12.0494 11.5575 11.9788 11.7278 11.8533 11.8533C11.7278 11.9788 11.5575 12.0494 11.38 12.0494C11.2024 12.0494 11.0322 11.9788 10.9066 11.8533L7.69329 8.63331L4.47329 11.8533C4.34776 11.9788 4.1775 12.0494 3.99996 12.0494C3.82243 12.0494 3.65216 11.9788 3.52663 11.8533C3.40109 11.7278 3.33057 11.5575 3.33057 11.38C3.33057 11.2921 3.34788 11.205 3.38152 11.1238C3.41516 11.0426 3.46447 10.9688 3.52663 10.9066L6.74663 7.69331L3.53329 4.46664C3.40776 4.34111 3.33723 4.17084 3.33723 3.99331C3.33723 3.81577 3.40776 3.64551 3.53329 3.51997C3.65883 3.39444 3.82909 3.32391 4.00663 3.32391C4.18416 3.32391 4.35443 3.39444 4.47996 3.51997L7.69996 6.73997L10.9133 3.51997C10.9755 3.45782 11.0492 3.40851 11.1305 3.37487C11.2117 3.34123 11.2987 3.32391 11.3866 3.32391C11.4745 3.32391 11.5616 3.34123 11.6428 3.37487C11.724 3.40851 11.7978 3.45782 11.86 3.51997C11.9221 3.58213 11.9714 3.65593 12.0051 3.73714C12.0387 3.81836 12.056 3.9054 12.056 3.99331C12.056 4.08121 12.0387 4.16826 12.0051 4.24947C11.9714 4.33069 11.9221 4.40448 11.86 4.46664L8.63996 7.68664L11.86 10.9L11.8533 10.9066Z" fill="currentColor"/>
  </Icon>
}

function CloseButton(props) {
  return <Button {...props}><Close /></Button>
}

export default Close
export { CloseButton }
