import { Link, useParams, useRouteMatch } from 'react-router-dom'
import classNames from 'classnames'
import Circle from './icons/Circle'
import CircleCheck from './icons/CircleCheck'
import Milestone from './icons/Milestone'
import MilestoneCheck from './icons/MilestoneCheck'
import './../styles/ElementNavigationItem.css'

function ElementNavigationItem({ concept, element }) {
  // FIXME - refactor this...
  const { projectId, topicId, conceptId } = useParams()
  const url = `/projects/${projectId}/topics/${topicId}/concepts/${conceptId}/elements/${element.id}`
  const match = useRouteMatch(url)

  return (
    <Link
      to={`${element.id}`}
      className={classNames('CodedoorEditor__ElementNavigationItem', match ? 'active' : '')}
      aria-label={element.txt}
    >{
      element.isSummary
        ? concept.isComplete
          ? <MilestoneCheck />
          : <Milestone />
        : element.isComplete
          ? <CircleCheck />
          : <Circle />

    }</Link>
  )
}

export default ElementNavigationItem
