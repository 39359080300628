import { useState } from 'react'
import Editor from 'react-simple-code-editor'
import Prism from 'prismjs'

import './../styles/ElementEditor.css'

/**
 * Generate a line for our text editor.
 *
 * @param {string} line The content of the line
 */
const line = line => `<span class="CodedoorEditor__ElementEditor__LineNumber">${line}</span>`

/**
 * Generate the default lines.
 */
const placeholderLines = (() => {
  let append = ''
  for (let i = 1; i < 9; i++) {
    append += `\n${line('')}`
  }
  return append
})()

/**
 * Prepare syntax highlighting and line numbers for our text editor.
 *
 * @param {string} code The code to highlight.
 */
const highlight = code => {
  let highlighted = Prism.highlight(code, Prism.languages.markup, 'markup')
    .split('\n')
    .map(line)
    .join('\n')

  return highlighted += placeholderLines
}

function ElementEditor({ element, setElement }) {
  const [code, setCode] = useState(element.answer)

  if (element.answer !== code) setCode(element.answer)

  return (
    <main className="CodedoorEditor__ElementEditor">
      <header>
        <h1 className="title">{element.txt}</h1>
        <p className="lead" dangerouslySetInnerHTML={{__html: element.leadText}}></p>
      </header>

      <section className="CodedoorEditor__ElementEditor__CodeEditor__Wrapper">
        <Editor
          value={code}
          onValueChange={code => {
            element.updateAnswer(code, setElement)
            setCode(code)
          }}
          highlight={highlight}
          style={{
            fontFamily: 'monospace',
            fontSize: 16,
          }}
          className="CodedoorEditor__ElementEditor__CodeEditor"
        />
      </section>
    </main>
  )
}

export default ElementEditor
