import { createContext, useContext } from 'react'
import { useParams } from 'react-router-dom'
import Element from './models/Element'
import Topic from './models/Topic'
import { ElementsMap } from './state'

const ProjectContext = createContext()

/**
 * A hook to use the project context.
 */
function useProject() {
  return useContext(ProjectContext)
}

/**
 * A hook to get the current topic.
 */
function useTopic() {
  const project = useProject()
  const { topicId } = useParams()

  if (! topicId) return null

  const topic = project.topics.find(t => t.id == topicId) // eslint-disable-line eqeqeq

  return new Topic(topic)
}

/**
 * A hook to get the current concept.
 */
function useConcept() {
  const topic = useTopic()
  const { conceptId } = useParams()
  if (! conceptId) return null

  return topic.concepts.find(c => c.id == conceptId) // eslint-disable-line eqeqeq
}

/**
 * A hook to get the current element.
 */
function useElement() {
  const concept = useConcept()
  const { elementId } = useParams()

  if (! elementId) return null

  if (! ElementsMap[elementId]) {
    const element = concept.elements.find(e => e.id == elementId) // eslint-disable-line eqeqeq
    ElementsMap[elementId] = new Element(element)
  }

  return ElementsMap[elementId]
}

/**
 * Get the previous and next steps...
 */
function useStep() {
  const project = useProject()
  const topic   = useTopic()
  const concept = useConcept()
  const element = useElement()

  const isSummary = element.regex === 'recap'

  const findNext = (id, array) => {
    const index = array.findIndex(i => i.id == id) // eslint-disable-line eqeqeq
    return (index < array.length - 1) && array[index + 1]
  }

  const findPrevious = (id, array) => {
    const index = array.findIndex(i => i.id == id) // eslint-disable-line eqeqeq
    return (index > 0) && array[index - 1]
  }

  // FIXME: Refactor this...
  let next
  const nextElement = findNext(element.id, concept.elements)
  if (nextElement) {
    next = `${nextElement.id}`
  } else {
    const nextConcept = findNext(concept.id, topic.concepts)
    const nextTopic   = findNext(topic.id, project.topics)
    next = nextConcept
      ? `./../../${nextConcept.id}`
      : nextTopic
        ? `./../../../../${nextTopic.id}`
        : false
  }

  // FIXME: Refactor this...
  let previous
  if (isSummary) {
    previous = `${concept.elements[concept.elements.length - 1].id}`
  } else  {
    const previousElement = findPrevious(element.id, concept.elements)
    const previousConcept = findPrevious(concept.id, topic.concepts)
    const previousTopic   = findPrevious(topic.id, project.topics)
    previous = previousElement
      ? `${previousElement.id}`
      : previousConcept
        ? `./../../${previousConcept.id}`
        : previousTopic && `./../../../../${previousTopic.id}`
  }

  return { next, previous }
}

export {
  ProjectContext,
  useProject,
  useTopic,
  useConcept,
  useElement,
  useStep,
}
