import { elementLocalStorageKey, storage } from './../../helpers/LocalStorage'
import { throttle } from 'throttle-debounce'
import RegexParser from 'regex-parser'
import API from '../../api'

const SUMMARY_KEY = 'recap'

const ElementUpdater = {}

class Element {
  constructor ({
    id,
    txt,
    description,
    regex = '//g',
    answer,
    version = 1,
  }) {
    this.id = id
    this.txt = txt
    this.description = description
    this.regex = regex
    this.version = version

    this.answer = answer || storage.getItem(this.localStorageKey, '')
    this.updateProgress()

    if (! ElementUpdater[this.id]) {
      ElementUpdater[this.id] = throttle(2000, (answer, cb) => {
        this.updateProgress()
        storage.setItem(this.localStorageKey, answer)
        API.post(`/elements/${this.id}/hits`, { answer })
        cb(new Element({...this, version: this.version + 1}))
      })
    }
    this.saveAnswer = ElementUpdater[this.id]
  }

  get localStorageKey () {
    return elementLocalStorageKey(this.id)
  }

  get isSummary () {
    return this.regex === SUMMARY_KEY
  }

  get isComplete () {
    return this.progress >= 50
  }

  get isWordMatcher () {
    return this.regex.match(/\|/g)
  }

  get cleanWordsList () {
    return this.regex.replace(/\//g, '')
  }

  get keywords () {
    return this.cleanWordsList.split('|')
  }

  get requiredMatchCount () {
    return this.keywords.length || 1
  }

  get regexp () {
    try {
      if (! this.isWordMatcher) {
        return RegexParser(this.regex)
      }

      return new RegExp(this.cleanWordsList, 'gmi')
    } catch (e) {
      console.error(e)
      return new RegExp('//', 'gmi')
    }
  }

  get matches () {
    if (! this.answer) return []

    const matches = this.regexp.global
      ? [...this.answer.matchAll(this.regexp)]
      : [this.answer.match(this.regexp)]

    return Array.from(new Set(matches.flat()))
  }

  get leadText () {
    return this.isSummary
      ? `Any other closing thoughts or notes?`
      : this.description || `What did you learn about "${this.txt}"?`
  }

  updateAnswer (answer, cb) {
    this.answer = answer
    this.saveAnswer(answer, cb)
  }

  computeProgress () {
    if (!this.regexp.global) {
      return this.answer.match(this.regexp) ? 100 : 0
    }

    return Math.min(100, Math.ceil((this.matches.length / this.requiredMatchCount) * 100))
  }

  updateProgress () {
    this.progress = this.computeProgress()
  }
}

export default Element
