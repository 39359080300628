import { Redirect, useParams } from 'react-router-dom'
import { useProject } from '../state/project'

function ChooseStep() {
  const project = useProject()
  const {
    topicId   = project.topics[0].id,
    conceptId = project.topics.find(t => t.id == topicId).concepts[0].id, // eslint-disable-line eqeqeq
    elementId = project.topics.find(t => t.id == topicId).concepts.find(c => c.id == conceptId).elements[0].id, // eslint-disable-line eqeqeq
  } = useParams()

  return (
    <Redirect to={`/projects/${project.id}/topics/${topicId}/concepts/${conceptId}/elements/${elementId}`} />
  )
}

export default ChooseStep
