import API from './index'
import { elementLocalStorageKey, storage } from './../helpers/LocalStorage'

async function loadProject({ id }) {
  const project = (await API.get(`/projects/${id}/tree`)).data

  const answers = (await API.get(`/projects/${id}/answers`)).data

  // Save the answers to local storage
  answers.forEach(({ fibo, answer }) => {
    if (!answer) return

    storage.setItem(elementLocalStorageKey(fibo), answer)
  })

  return project
}

export { loadProject }
