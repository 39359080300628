import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom'
import Modal from 'react-modal'

import './styles/index.css'
import './styles/Modal.css'

import { ProvideAppState } from './state/app'
import AppShell from './components/AppShell'
import HydrateSession from './components/HydrateSession'
import Project from './components/Project'

Modal.setAppElement('#root')

function CodedoorEditor({
  basename = '/',
}) {
  return (
    <ProvideAppState>
      <Router basename={basename}>
        <AppShell>
          <Switch>
            {/* <Route exact path="/projects">
              <ChooseProject />
            </Route> */}

            <Route path="/projects/:projectId">
              <Project />
            </Route>

            <Route path="*">
              <HydrateSession />
            </Route>
          </Switch>
        </AppShell>
      </Router>
    </ProvideAppState>
  )
}

export default CodedoorEditor
