import Modal from 'react-modal'
import Button from './Button'
import { useState } from 'react'


function HintModal ({ element }) {
  const [showHint, setShowHint] = useState(false)

  if (element.isSummary) return null

  const unmatched = element.keywords.filter(k => ! element.matches.includes(k))

  if (! unmatched.length) return null

  const hint = unmatched[0]

  return <div>
    <Button onClick={() => setShowHint(true)}>Give me a Hint</Button>
    <Modal isOpen={showHint} closeTimeoutMS={300}>
      <h1 className="title">Hint</h1>
      <p className="lead">Did you already find anything about “{hint}” regarding “{element.txt}”?</p>
      <footer>
        <Button onClick={() => setShowHint(false)}>Continue</Button>
      </footer>
    </Modal>
  </div>
}

export default HintModal
